<template>
  <div class="hello">
      <div id='container'>
        <div  id='DmanTip' class="position-item"> 
          <div id='date'>
            Fecha: 26.03.2021 09:58:31 UTC
          </div>
          <div id='tip'>
            BZRX - very good position now. If it wouldn't be this big already, I'd enter it with gem allocation, but since we made so much on it, this is like your 'SAFE' alt. 1) tested to the bones 2) undermarketed like always 3) near the completion of big things which market will react to regardless of their marketing you know it won't be the fastest grower, but it will be very safe even in crazy volatile times. Plus it might surprise us. Real target, nowhere before ATHs.
            
          </div>
      </div>
        <div  id='DmanTip' class="position-item"   >
          <div id='date'>
            Fecha:  10.03.2021 15:31:45 UTC
          </div>
          <div id='tip'>
            You can stake bzrx too, if you aren't
          </div>
      </div>
        <div  id='DmanTip' class="position-item" >
          <div id='date'>
            Fecha: 28.02.2021 15:51:33 UTC
          </div>
          <div id='tip'>
            BZRX still has great news to come in the next 3 months or less... don't be itchy, be smart
          </div>
      </div>
    </div>
</div>
</template>

<script>
const API_URL = 'https://api.maniacmansion.cloud'
export default {
  name: 'BZRX',
  data () {
    return {
    }
  },
  methods: {
  },
}

</script>
